.c-rr-time-signature-selection {
  &__fieldset {
    padding: 1rem 0.5rem 0 0.5rem;
    margin-bottom: 1rem;
  }

  &__label-container {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-end;
  }

  &__label {
    display: flex;
    align-items: center;
    margin-right: 1rem;
    margin-bottom: 1rem;
  }

  &__icon {
    height: 30px;
    margin-left: 0.5rem;

    &--C {
      height: 20px;
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }
}
