@import './styles/variables.scss';
@import './styles/common.scss';

.c-rr-app {
  position: relative;
  overflow: hidden;

  &__error-container {
    position: absolute;
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    background-color: $brand-color;
    color: white;
    font-size: 1.5rem;
    padding: 3rem;

    a {
      color: $link-light;

      &:visited {
        color: $link-light;
      }
    }

    @media (max-width: 768px) {
      padding: 1rem;
      font-size: 1rem;
    }
  }

  &__error {
    max-width: 800px;
  }

  a {
    color: $link;

    &:visited {
      color: $link-visited;
    }
  }
}
