.c-rr-note-checkbox-group {
  padding: 1rem 0.5rem 0 0.5rem;
  margin-bottom: 1rem;

  &__multi-select-container {
    float: right;
    position: relative;
    top: -15px;
  }

  &__multi-select-button {
    padding: 3px;
    border: 1px solid black;
    border-radius: 0.25rem;

    img {
      display: block;
    }
  }

  &__label-container {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-end;
  }

  &__label {
    margin-right: 1rem;
    margin-bottom: 1rem;
  }

  &__icon {
    height: 30px;
    margin-left: 0.5rem;

    &--w,
    &--wd,
    &--cwd {
      height: 8px;
    }

    &--wr {
      height: 7px;
    }

    &--cwdr {
      height: 7px;
    }

    &--hr {
      height: 7px;
    }

    &--chdr {
      height: 7px;
    }

    &--eqd {
      height: 30px;
    }
  }

  &__checkbox.c-rr-checkbox {
    margin-right: 3px;
  }
}
