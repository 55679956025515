@import '../styles/variables.scss';

.c-rr-settings-menu {
  &__pane {
    left: -500px;
    transition: left 250ms ease;

    &--open {
      left: 0;
      border-right: 2px solid black;
    }
  }

  &__close-button {
    .c-rr-icon-button__icon {
      width: 25px;
    }
  }

  &__button-container {
    padding: 1rem 1rem 0 1rem;
    display: flex;
    justify-content: space-between;
  }
}
